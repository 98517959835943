import React from 'react'
import { useIconSize } from '../../utils/svgSize';

function Unfav(props) {
  const getSize = useIconSize(["32px","32px", '40px', "48px"], props.id);
  return (
    <div className='icon-svg'>
      <svg className='icon-svg-star' id={props.id} width={getSize} height={getSize} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${getSize} ${getSize}`}>
        <defs>
          <path d="m21.286 10.07-5.243-.456-2.048-4.82c-.368-.878-1.625-.878-1.993 0L9.954 9.624l-5.232.444c-.954.076-1.344 1.268-.618 1.896L8.08 15.41l-1.192 5.113c-.216.932.791 1.669 1.615 1.17l4.495-2.708 4.496 2.72c.824.497 1.831-.24 1.614-1.17l-1.191-5.125 3.976-3.445c.725-.628.346-1.82-.607-1.896zm-8.288 6.89-4.073 2.458 1.083-4.636-3.596-3.12 4.745-.412 1.841-4.366 1.853 4.377 4.745.411-3.597 3.12 1.084 4.637-4.085-2.47z" id="x9c700eooa" />
        </defs>
        <g fill="none" fill-rule="evenodd">
          <mask id="jvcozgf2ob" fill="#fff">
            <use xlinkHref="#x9c700eooa" />
          </mask>
          <g mask="url(#jvcozgf2ob)" fill="#FFF">
            <path d="M0 0h26v26H0z" />
          </g>
        </g>

      </svg>
    </div>
  )
}

export { Unfav }