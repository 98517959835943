import React from 'react'
import { useIconSize } from '../../utils/svgSize';

const Fav2000 = (props) => {
    const { getSize, id } = props
    return (
        <div className='icon-svg'>
            <svg className='icon-svg-star' id={`${id}`} width={getSize} height={getSize} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${getSize} ${getSize}`}>
                <defs>
                    <path d="m16 23.36 5.533 3.346c1.014.613 2.254-.293 1.987-1.44l-1.467-6.293 4.894-4.24c.893-.774.413-2.24-.76-2.334l-6.44-.546-2.52-5.947c-.454-1.08-2-1.08-2.454 0l-2.52 5.933-6.44.547c-1.173.093-1.653 1.56-.76 2.333l4.894 4.24-1.467 6.294c-.267 1.146.973 2.053 1.987 1.44L16 23.359z" id="3plngt996a" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <mask id="s0utpdfozb" fill="#fff">
                        <use xlinkHref="#3plngt996a" />
                    </mask>
                    <g mask="url(#s0utpdfozb)" fill="#FFF">
                        <path d="M0 0h32v32H0z" />
                    </g>
                </g>

            </svg>
        </div>
    )
}

const FavGernal = (props) => {
    const { getSize, id } = props

    return (
        <div className='icon-svg'>
            <svg className='icon-svg-star' id={`${id}`} width={getSize} height={getSize} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${getSize} ${getSize}`}>
                <defs>
                    <path d="m13 18.98 4.496 2.719c.823.498 1.83-.239 1.614-1.17l-1.192-5.114 3.976-3.445c.726-.628.336-1.82-.617-1.895l-5.233-.445L13.997 4.8c-.369-.878-1.625-.878-1.994 0l-2.047 4.82-5.233.445c-.953.076-1.343 1.267-.617 1.896l3.976 3.445-1.192 5.113c-.217.932.79 1.668 1.614 1.17L13 18.98z" id="o94199r8oa" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <mask id="hj6bsghayb" fill="#fff">
                        <use xlinkHref="#o94199r8oa" />
                    </mask>
                    <g mask="url(#hj6bsghayb)" fill="#FFF">
                        <path d="M0 0h26v26H0z" />
                    </g>
                </g>

            </svg>
        </div>
    )
}

function Fav(props) {

    const ids = [`${props.id}-2000`,`${props.id}-genral`]
    const getSize = useIconSize(["32px","32px",'40px', "48px"], props.id);
    return (
        <FavGernal id={props.id} getSize={getSize} />
    )
}

export { Fav }