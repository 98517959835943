import React from "react";

const ImageGrid = (props) => {

    const getGridImg = (imageName) => {
        const bgImage =require(`../../assets/images/${imageName}`)
          try {
            return bgImage;
          } catch {
            return require(`../../assets/images/class1.jpg`)
          }
      }
 
    return <div className="image-grid-container"> 
                <div className="list">
                    {props.imageList.map(img => ( 
                    <div style={{backgroundImage:`url(${getGridImg(img.image)})`}}
                        className="imageHolder channel-page-card makeTextClickable">
                        </div>))}
                </div>
            </div>
}

export default ImageGrid;