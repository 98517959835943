import React from 'react'
import { useIconSize } from '../../utils/svgSize';

function ShareIcon(props) {
     const getSize = useIconSize(["32px","32px",'40px', "48px"], props.id);
    
    return (
        <div className='icon-svg'>
            <svg className='icon-svg-share' id={props.id} width={getSize} height={getSize} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${getSize} ${getSize}`}>
                <defs>
                    <path id="m8biiq38ya" d="M7.917 6.667v1.666H5a.836.836 0 0 0-.833.834V17.5c0 .458.375.833.833.833h10a.836.836 0 0 0 .833-.833V9.167A.836.836 0 0 0 15 8.333h-3.148V6.667h3.981c.917 0 1.667.75 1.667 1.666v10c0 .917-.75 1.667-1.667 1.667H4.167C3.25 20 2.5 19.25 2.5 18.333v-10c0-.916.75-1.666 1.667-1.666h3.75zM9.926.174c.16 0 .313.063.426.177l3.978 3.978a.601.601 0 0 1 0 .85.608.608 0 0 1-.858 0l-2.945-2.945v8.91a.605.605 0 0 1-.604.603.605.605 0 0 1-.604-.603v-8.91l-2.952 2.94a.601.601 0 1 1-.85-.851L9.5.35a.601.601 0 0 1 .426-.177z" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <mask id="8sg5e9t0bb" fill="#fff">
                        <use xlinkHref="#m8biiq38ya" />
                    </mask>
                    <g mask="url(#8sg5e9t0bb)" fill="#FFF">
                        <path d="M0 0h20v20H0z" />
                    </g>
                </g>
            </svg>

        </div>
    )
}

export { ShareIcon }