
var _Environments = {
    production: { BASE_URL: 'https://api.wexer.com/',JWPLAYER:'95riWD5s.js', API_KEY: '1234123412341234', API_SECRET: '573572796441127398913495789868468206481',RESET_PASSWORD: "https://mypuregym.swiss" },
    test: { BASE_URL: 'https://stage.api.wexer.com/',JWPLAYER:'wUkkXJdm.js', API_KEY: '1234123412341234', API_SECRET: '573572796441127398913495789868468206481',RESET_PASSWORD: "https://mypuregym.swiss" },
    stage: { BASE_URL: 'https://stage.api.wexer.com/',JWPLAYER:'wUkkXJdm.js', API_KEY: '1234123412341234', API_SECRET: '573572796441127398913495789868468206481',RESET_PASSWORD: "https://mypuregym.swiss" },
    development: { BASE_URL: "https://ff-int.api.wexer.com/",JWPLAYER:'HofBP5zj.js', API_KEY: '1234123412341234', API_SECRET: '573572796441127398913495789868468206481',RESET_PASSWORD: "https://mypuregym.swiss" },
}

  function getEnvironment() {
    const PLATFORM = process.env.REACT_APP_PLATFORM;
  
    let platform  //getPlatform();
    if (PLATFORM === "FF-INT") {
      platform = "development";
    } else if (PLATFORM === "STAGE") {
      platform = "stage";
    } else if (PLATFORM === "PROD") {
      platform = "production";
    }
  
    return _Environments[platform];
  }
  
  var Environment = getEnvironment();
  module.exports = Environment;
  
  